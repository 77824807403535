import { Component, OnInit } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in5-o',
  templateUrl: './in5-o.component.html',
  styleUrls: ['./in5-o.component.scss']
})
export class In5OComponent implements OnInit {
  docs: any;
  docs18: any;
  docs18ene: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  area: any;
  displayStyleEditarNombre = "none";

  borrar: string[] = [];
  valor: any;
  todo: any;

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '5');
    localStorage.setItem('inciso', '16');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.general.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }

  checkCheckBoxvalue(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo(){
    this.todo = true;
    let valores = this.docs.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }

  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.general.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }

  a82019ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 1, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019FEB() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019MAR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 3, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019ABR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 4, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019MAY() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 5, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019JUN() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 6, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019JUL() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 7, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019AGO() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 8, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019SEP() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 9, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019OCT() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 10, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019NOV() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 11, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82019DIC() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 12, 70).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82018ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 1, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018FEB() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018MAR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 3, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018ABR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 4, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018MAY() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 5, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018JUN() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 6, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018JUL() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 7, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018AGO() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 8, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018SEP() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 9, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018OCT() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 10, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018NOV() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 11, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82018DIC() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 12, 70).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82020ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 1, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020FEB() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020MAR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 3, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020ABR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 4, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020MAY() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 5, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020JUN() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 6, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020JUL() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 7, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020AGO() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 8, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020SEP() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 9, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020OCT() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 10, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020NOV() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 11, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82020DIC() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 12, 70).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82022ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 1, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022FEB() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022MAR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 3, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022ABR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 4, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022MAY() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 5, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022JUN() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 6, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022JUL() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 7, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022AGO() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 8, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022SEP() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 9, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022OCT() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 10, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022NOV() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 11, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }



  a82022DIC() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 12, 70).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }



  a82021ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 1, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021FEB() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021MAR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 3, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021ABR() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 4, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021MAY() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 5, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021JUN() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 6, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021JUL() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 7, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021AGO() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 8, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021SEP() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 9, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021OCT() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 10, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021NOV() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 11, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }



  a82021DIC() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 12, 70).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82019ENE1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 1, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019FEB1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019MAR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 3, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019ABR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 4, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019MAY1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 5, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }

  a82019JUN1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 6, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019JUL1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 7, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019AGO1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 8, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019SEP1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 9, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019OCT1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 10, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  a82019NOV1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 11, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82019DIC1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 12, 71).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82018ENE1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 1, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018FEB1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018MAR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 3, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018ABR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 4, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018MAY1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 5, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }

  a82018JUN1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 6, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018JUL1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 7, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018AGO1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 8, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018SEP1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 9, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018OCT1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 10, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }


  a82018NOV1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 11, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82018DIC1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 12, 71).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log('18');
      console.log(this.area);
    })
  }



  a82020ENE1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 1, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020FEB1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020MAR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 3, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020ABR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 4, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020MAY1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 5, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }

  a82020JUN1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 6, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020JUL1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 7, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020AGO1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 8, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020SEP1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 9, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020OCT1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 10, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }


  a82020NOV1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 11, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82020DIC1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 12, 71).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82022ENE1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 1, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022FEB1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022MAR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 3, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022ABR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 4, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022MAY1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 5, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82022JUN1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 6, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022JUL1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 7, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022AGO1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 8, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022SEP1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 9, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022OCT1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 10, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  a82022NOV1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 11, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }



  a82022DIC1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 12, 71).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }



  a82021ENE1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 1, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021FEB1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021MAR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 3, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021ABR1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 4, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021MAY1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 5, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }

  a82021JUN1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 6, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021JUL1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 7, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021AGO1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 8, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021SEP1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 9, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021OCT1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 10, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }


  a82021NOV1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 11, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }



  a82021DIC1() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 12, 71).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  openModalEditarNombre() {
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }
}
