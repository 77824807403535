import { Component, OnInit } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in6-d',
  templateUrl: './in6-d.component.html',
  styleUrls: ['./in6-d.component.scss']
})
export class In6DComponent implements OnInit {
  docs: any;
  docs18: any;
  docs18ene: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  area: any;
  displayStyleEditarNombre = "none";

  borrar: string[] = [];
  valor: any;
  todo: any;

  meses: any;
  ano: any;
  carpeta: any;


  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '6');
    localStorage.setItem('inciso', '4');


    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.general.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }


  checkCheckBoxvalue(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo(){
    this.todo = true;
    let valores = this.docs.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }

  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.general.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }

  a82019ENE() {
    this.service.obtenerarpetaMes(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), localStorage.getItem('anoF6D'), localStorage.getItem('mesF6D'), 60).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }


  anos2018() {
    localStorage.setItem('anoF6D', '2018')
  }
  anos2019() {
    localStorage.setItem('anoF6D', '2018')
  }
  anos2020() {
    localStorage.setItem('anoF6D', '2018')
  }
  anos2021() {
    localStorage.setItem('anoF6D', '2018')
  }
  anos2022() {
    localStorage.setItem('anoF6D', '2018')
  }
  mes() {
    localStorage.setItem('mesF6D', '1')
  }
  mes2() {
    localStorage.setItem('mesF6D', '2')
  }
  mes3() {
    localStorage.setItem('mesF6D', '3')
  }
  mes4() {
    localStorage.setItem('mesF6D', '4')
  }
  mes5() {
    localStorage.setItem('mesF6D', '5')
  }
  mes6() {
    localStorage.setItem('mesF6D', '6')
  }
  mes7() {
    localStorage.setItem('mesF6D', '7')
  }
  mes8() {
    localStorage.setItem('mesF6D', '8')
  }
  mes9() {
    localStorage.setItem('mesF6D', '9')
  }
  mes10() {
    localStorage.setItem('mesF6D', '10')
  }
  mes11() {
    localStorage.setItem('mesF6D', '11')
  }
  mes12() {
    localStorage.setItem('mesF6D', '12')
  }



  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }
}
