import { Component, OnInit } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in6-f',
  templateUrl: './in6-f.component.html',
  styleUrls: ['./in6-f.component.scss']
})
export class In6FComponent implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docsSubsidio: any;
  boton: any;
  tri: any;
  anu: any;
  fecha: any;
  area: any;
  displayStyleEditarNombre = "none";

  borrar: string[] = [];
  valor: any;
  todo: any;

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '6');
    localStorage.setItem('inciso', '6');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
    this.general.obtenerA8(localStorage.getItem('fraccion'), localStorage.getItem('inciso')).subscribe(res => {
      this.docs = res.body
      console.log(this.docs);

    })
  }

  checkCheckBoxvalue(event:any, index:any, id: any){
    console.log(event.target.checked)
    this.todo = null;
    this.valor = event.target.checked;
    if(this.valor === true){
      console.log('verdadero');
      console.log(index);
      this.borrar[index]=this.docs[index].ID;
      console.log(this.borrar);

    }else if(this.valor === false){
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo(){
    this.todo = true;
    let valores = this.docs.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar[i]=this.docs[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion(){
    let valores = this.borrar.length;
    for( let i = 0; i < valores; i++){
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo = false;
    }
  }

  eliminarSeleccionados(){

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
    .then((res: any) => {

      if (res.isConfirmed) {
        for(let numero of this.borrar){
         console.log(numero);
         this.eliminarForm.ID = numero;
         this.eliminarForm.ESTATUS = 1;
         this.general.eliminarEnlace(this.eliminarForm).subscribe(
           (data: any) => {

           })
         this.alerts.alertaRealizado().then((res: any) => {
          location.reload()
         })

        }

      }

    })
  }

  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }


  ar820181() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2005).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820182() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2006).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820183() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2007).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820184() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2008).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }

  ar820191() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2005).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }
  ar820192() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2006).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }  ar820193() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2007).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }  ar820194() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2008).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }

  ar820201() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2005).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
  ar820202() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2006).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
   ar820203() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2007).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
  ar820204() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020,2008).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }


  ar820211() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2005).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }

  ar820212() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021,2006).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820213() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2007).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820214() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2008).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820221() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2005).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820222() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2006).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820223() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2007).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820224() {
    localStorage.setItem('fecha', '2022');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2008).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }



















  a82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 19).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }



  a82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 19).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 19).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 19).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }




  a82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 19).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }




  r82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 53).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  r82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 53).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  r82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 53).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  r82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 53).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  r82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 53).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  e82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 18).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  e82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 18).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  e82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 18).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  e82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 18).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  e82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 18).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  c82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 20).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  c82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 20).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  c82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 20).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  c82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 20).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  c82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 20).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  contr82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 51).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  contr82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 51).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  contr82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 51).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  contr82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 51).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  contr82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 51).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  agua82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 52).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  agua82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 52).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  agua82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 52).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  agua82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 52).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  agua82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 52).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  fiscal82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 55).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  fiscal82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 55).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  fiscal82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 55).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  fiscal82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 55).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  fiscal82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 55).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  personal82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 54).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  personal82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 54).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  personal82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 54).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  personal82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 54).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  personal82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 54).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  area1() {
    localStorage.setItem('carpeta', '2005');
    this.tri=localStorage.getItem('carpeta')
  }
  area2() {
    localStorage.setItem('carpeta', '2006');
     this.tri=localStorage.getItem('carpeta')
  }
  area3() {
    localStorage.setItem('carpeta', '2007');
    this.tri=localStorage.getItem('carpeta')
  }
  area4() {
    localStorage.setItem('carpeta', '2008');
     this.tri=localStorage.getItem('carpeta')
  }
}
