<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">

    <section style="margin-top: 40px;">
      <app-menu-compartido></app-menu-compartido>
      <div class="section-title" data-aos="fade-up">
        <h4 style="text-align: left; margin-left: 160px;margin-top:3vh">El estado de la deuda pública del sujeto obligado,
          donde se señale, responsable de la autorización,
          fecha de contratación, monto del crédito, tasa de interés,
          monto total amortizable, plazo de vencimiento, institución crediticia,
          objeto de aplicación y avance de aplicación de cada deuda contratada</h4>
      </div>
    </section>

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Inicio</li>
            <li><a href="admin/articulo-8">Articulo 8</a></li>
            <li>Fracción V</li>
            <li>Inciso W</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <section id="blog" class="blog">
      <!-- ======= Inicio Seccion Carpetas por año ======= -->
      <div class="fondo2">
        <div id="scroll">
      <div class="panel-group" id="accordion" style="height:600px;width: 100%;">







        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82018()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2018</i></button>
            </h4>
          </div>
          <div id="collapse1" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                  <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                  class="btn btn-outline-dark">Seleccionar Todo</button>
                  <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                  class="btn btn-outline-dark">Desahacer Selección</button>
                  <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                  class="btn btn-outline-dark">Eliminar Seleccionados</button>
                </div>
                <div class="fondo">

                  <div id="scroll">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                            documento</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let dato of docs18, let i = index">
                          <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                            <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                            <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                          </td>

                          <td>
                            <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                              class="btn btn-outline-dark">Eliminar</button>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82019()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse2"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2019</i></button>
            </h4>
          </div>
          <div id="collapse2" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs19, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>

                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82020()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse3"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2020</i></button>
            </h4>
          </div>
          <div id="collapse3" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs20, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82021()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse4"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2021</i></button>
            </h4>
          </div>
          <div id="collapse4" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs21, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82022()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse5"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2022</i></button>
            </h4>
          </div>
          <div id="collapse5" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                class="btn btn-outline-dark">Seleccionar Todo</button>
                <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                class="btn btn-outline-dark">Desahacer Selección</button>
                <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                class="btn btn-outline-dark">Eliminar Seleccionados</button>
              </div>
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs22, let i = index">
                        <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                          <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82023()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse51"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2023</i></button>
            </h4>
          </div>
          <div id="collapse51" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs23">
                        <td><img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82024()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse51c"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2024</i></button>
            </h4>
          </div>
          <div id="collapse51c" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs24">
                        <td><img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title">
              <button (click)="a82025()" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse51c25"
                style="background: none; border: none;">
                <i class="bi bi-folder-fill"> 2025</i></button>
            </h4>
          </div>
          <div id="collapse51c25" class="panel-collapse collapse in" data-bs-parent="#accordion">
            <div class="panel-body">
              <div class="fondo">

                <div id="scroll">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                          documento</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let dato of docs25">
                        <td><img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                          <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                        </td>
                        <td>
                          <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                            class="btn btn-outline-dark">Eliminar</button>
                        </td>

                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>





      </div>
    </div>
  </div>



      <!--Ventana Modal Editar Nombre-->

      <!--Termina Ventana Modal Accion Editar Nombre-->


    </section>

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
