<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <!--Main layout-->
  <main id="main">

      <!-- Sidebar -->
      <nav class="collapse d-lg-block sidebar collapse bg-white" style="height: 700px;">

        <div class="offcanvas-header" style="text-align: center; margin-top: 20px;">
          <span class="fs-4" style="width: 100%; margin-top: 20px;">Artículo</span>
        </div>
        <div id="scroll2">
          <div style="padding: 16px; height:50%;">
            <div id="sidebar" class="text-ceter" style="width: 90%;">
              <div class="nav flex-column py-3">
                <ul class="list-unstyled ps-0">
                  <ul class="list-unstyled ps-0"><strong>Artículo 8</strong>
                    <li class="mb-1">
                      <button href="#fraccion1" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción I
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion1" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-1/in-a" (click)="fraccion1a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-b" (click)="fraccion1b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-c" (click)="fraccion1c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-d" (click)="fraccion1d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-e" (click)="fraccion1e()">Inciso E</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-f" (click)="fraccion1f()">Inciso F</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-g" (click)="fraccion1g()">Inciso G</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-h" (click)="fraccion1h()">Inciso H</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-i" (click)="fraccion1i()">Inciso I</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-j" (click)="fraccion1j()">Inciso J</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-k" (click)="fraccion1k()">Inciso K</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-l" (click)="fraccion1l()">Inciso L</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-m" (click)="fraccion1m()">Inciso M</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-n" (click)="fraccion1n()">Inciso N</a> <br>
                        <a href="admin/articulo-8/fraccion-1/in-n1" (click)="fraccion1n1()">Inciso Ñ</a> <br>
                      </div>
                    </li>

                    <li class="mb-1">
                      <button href="#fraccion2" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción II
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion2" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-2/in-a" (click)="fraccion2a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-2/in-b" (click)="fraccion2b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-2/in-c" (click)="fraccion2c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-2/in-d" (click)="fraccion2d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-2/in-e" (click)="fraccion2e()">Inciso E</a> <br>

                      </div>
                    </li>

                    <li class="mb-1">
                      <button href="#fraccion3" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción III
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion3" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-3/in-a" (click)="fraccion3a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-b" (click)="fraccion3b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-c" (click)="fraccion3c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-d" (click)="fraccion3d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-e" (click)="fraccion3e()">Inciso E</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-f" (click)="fraccion3f()">Inciso F</a> <br>
                        <a href="admin/articulo-8/fraccion-3/in-g" (click)="fraccion3g()">Inciso G</a> <br>

                      </div>
                    </li>

                    <li class="mb-1">
                      <button href="#fraccion4" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción IV
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion4" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-4/in-a" (click)="fraccion4a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-b" (click)="fraccion4b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-c" (click)="fraccion4c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-d" (click)="fraccion4d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-e" (click)="fraccion4e()">Inciso E</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-f" (click)="fraccion4f()">Inciso F</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-g" (click)="fraccion4g()">Inciso G</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-h" (click)="fraccion4h()">Inciso H</a> <br>
                        <a href="admin/articulo-8/fraccion-4/in-i" (click)="fraccion4i()">Inciso I</a> <br>

                      </div>
                    </li>

                    <li class="mb-1">
                      <button href="#fraccion5" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción V
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion5" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-5/in-a" (click)="fraccion5a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-b" (click)="fraccion5b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-c" (click)="fraccion5c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-d" (click)="fraccion5d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-e" (click)="fraccion5e()">Inciso E</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-f" (click)="fraccion5f()">Inciso F</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-g" (click)="fraccion5g()">Inciso G</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-h" (click)="fraccion5h()">Inciso H</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-i" (click)="fraccion5i()">Inciso I</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-j" (click)="fraccion5j()">Inciso J</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-k" (click)="fraccion5k()">Inciso K</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-l" (click)="fraccion5l()">Inciso L</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-m" (click)="fraccion5m()">Inciso M</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-n" (click)="fraccion5n()">Inciso N</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-n1" (click)="fraccion5n1()">Inciso Ñ</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-o" (click)="fraccion5o()">Inciso O</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-p" (click)="fraccion5p()">Inciso P</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-q" (click)="fraccion5q()">Inciso Q</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-r" (click)="fraccion5r()">Inciso R</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-s" (click)="fraccion5s()">Inciso S</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-t" (click)="fraccion5t()">Inciso T</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-u" (click)="fraccion5u()">Inciso U</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-v" (click)="fraccion5v()">Inciso V</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-w" (click)="fraccion5w()">Inciso W</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-x" (click)="fraccion5x()">Inciso X</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-y" (click)="fraccion5y()">Inciso Y</a> <br>
                        <a href="admin/articulo-8/fraccion-5/in-z" (click)="fraccion5z()">Inciso Z</a> <br>
                      </div>
                    </li>

                    <li class="mb-1">
                      <button href="#fraccion6" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                        style="background: none; border: none;">
                        Fracción VI
                      </button>
                      <div class="collapse animate__animated animate__zoomIn" id="fraccion6" data-bs-parent="#sidebar"
                        style="margin-left: 15px;">

                        <a href="admin/articulo-8/fraccion-6/in-a" (click)="fraccion6a()">Inciso A</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-b" (click)="fraccion6b()">Inciso B</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-c" (click)="fraccion6c()">Inciso C</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-d" (click)="fraccion6d()">Inciso D</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-e" (click)="fraccion6e()">Inciso E</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-f" (click)="fraccion6f()">Inciso F</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-g" (click)="fraccion6g()">Inciso G</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-h" (click)="fraccion6h()">Inciso H</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-i" (click)="fraccion6i()">Inciso I</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-j" (click)="fraccion6j()">Inciso J</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-k" (click)="fraccion6k()">Inciso K</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-l" (click)="fraccion6l()">Inciso L</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-m" (click)="fraccion6m()">Inciso M</a> <br>
                        <a href="admin/articulo-8/fraccion-6/in-n" (click)="fraccion6n()">Inciso N</a> <br>
                      </div>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-7" (click)="a8fraccion7()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción VII
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-8" (click)="a8fraccion8()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción VIII
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-9" (click)="a8fraccion9()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción IX
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-10" (click)="a8fraccion10()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción X
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-11" (click)="a8fraccion11()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción XI
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-12" (click)="a8fraccion12()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción XII
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-13" (click)="a8fraccion13()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción XIII
                        </button></a>
                    </li>

                    <li class="mb-1">
                      <a href="admin/articulo-8/fraccion-14" (click)="a8fraccion14()"><button data-bs-toggle="collapse"
                          data-bs-parent="#sidebar" style="background: none; border: none;">
                          Fracción XIV
                        </button></a>
                    </li>

                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- Sidebar -->



  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
