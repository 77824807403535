import { Component } from '@angular/core';

@Component({
  selector: 'app-i-mujer',
  templateUrl: './i-mujer.component.html',
  styleUrls: ['./i-mujer.component.scss']
})
export class IMujerComponent {

}
