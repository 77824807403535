import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { RespuestaAPI } from 'src/interface/api-responses.model';
import { loginI } from 'src/interface/usuario.model';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  token=false;


  idUsuario: any = "";
  loginForm = new FormGroup({
    usuario: new FormControl('', Validators.required),
    contrasena: new FormControl('', Validators.required),
  });

  iniciandoSesion = false;

  constructor(
    private api: AuthService,
    private router: Router,
    private alertas: SweetAlertService
  ) {}

  errorStatus: boolean = false;
  errorMsj: any = '';
  login:any;

  ngOnInit(): void {
    this.checkLocalStorage();
    if(localStorage.getItem("token")){
      this.token=true;
    }
  }

  checkLocalStorage() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['admin']);
    }
  }

  onLogin(form: any) {
    this.login=true;
    this.iniciandoSesion = true;  //Mostrar spinner loading


      console.log(form);

    this.api.loginByUser(form).subscribe(
      (data) => {
        let dataResponse: RespuestaAPI = data;
        if (dataResponse.status == 200) {

          this.iniciandoSesion = false;
          localStorage.setItem('token', dataResponse.body);


          this.api.logueado=true;
          this.router.navigate(['/admin/inicio']);

        } else {
          this.iniciandoSesion = false;
          this.errorStatus = true;
          this.errorMsj = 'Datos incorrectos';
          console.log('error');
          this.alertas
            .alertaError('Error', 'Datos incorrectos')
            .then((res) => {});
        }
      },
      (err) => {
        this.iniciandoSesion = false;
        this.login=false;
      }
    );

  }

  cerrar(){
    localStorage.clear()
    this.router.navigate(['https://www.autlan.gob.mx/admin/indice']);
  }

}
