import { Component } from '@angular/core';

@Component({
  selector: 'app-documento-dif',
  templateUrl: './documento-dif.component.html',
  styleUrls: ['./documento-dif.component.scss']
})
export class DocumentoDifComponent {

}
