<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Singles - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">



    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog">
      <div style="margin-top: 150px;" class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-12 entries">

            <article class="entry entry-single">
              <div class="row">
                <div class="col-9">
                  <h2 class="entry-title">Artículo 8: Información Fundamental General</h2>
                </div>
                <div class="col-3">
                  <button style="border-radius: 1vh; width: 180px; " *ngIf="boton === 1"
                    (click)="subirDocumento()">Subir documento <i class="bi bi-cloud-arrow-up"></i></button>
                </div>
              </div>

              <div class="entry-content">
                <h4>Capítulo De la Información Fundamental Articulo 8 Información Fundamental - General</h4>
                <p style="text-align: justify; font-size: 2vh;">
                  <strong>1.</strong> Es información fundamental,
                  obligatoria para todos los sujetos obligados, la siguiente:
                  <br><br> <strong> I. La necesaria para el ejercicio del derecho a la información pública, que
                    comprende:</strong>
                  <br><a href="admin/articulo-8/fraccion-1/in-a" (click)="fraccion1a()"><strong>a)</strong> La Ley
                    General, la presente Ley y su Reglamento.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-b" (click)="fraccion1b()"><strong>b)</strong> El
                    reglamento interno para el manejo de la información pública del sujeto
                    obligado.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-c" (click)="fraccion1c()"><strong>c)</strong> Los
                    lineamientos estatales de clasificación de información pública, emitidos
                    por el Instituto.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-d" (click)="fraccion1d()"><strong>d)</strong> Los
                    lineamientos estatales
                    de publicación y actualización de información fundamental, emitidos por el Instituto.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-e" (click)="fraccion1e()"><strong>e)</strong> Los
                    lineamientos estatales de protección de información confidencial y
                    reservada, emitidos por el Instituto.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-f" (click)="fraccion1f()"><strong>f)</strong> Los
                    lineamientos generales que emita el Sistema Nacional.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-g" (click)="fraccion1g()"><strong>g)</strong> Las actas y
                    resoluciones del Comité de Transparencia.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-h" (click)="fraccion1h()"><strong> h)</strong> Dirección
                    electrónica donde podrán recibirse las solicitudes de acceso a la
                    información.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-i" (click)="fraccion1i()"><strong>i)</strong> La
                    denominación, domiclio, teléfonos, faxes,
                    dirección electrónica y correo electrónico oficiales del sujeto obligado.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-j" (click)="fraccion1j()"><strong>j)</strong> El
                    directorio de todos los servidores públicos del sujeto obligado,
                    a partir del nivel de jefe de departamento o su equivalente, o de menor nivel, cuando se brinde
                    atención al público; manejen o apliquen recursos
                    públicos; realicen actos de autoridad o presten servicios profesionales bajo el régimen de confianza
                    u
                    honorarios y personal de base. El directorio debera incluir, al menos, el nombre, cargo o
                    nombramiento asignado, nivel del puesto en la estructura orgánica, fecha de alta en el
                    cargo. número telefónico,
                    domicilio para recibir corre electrónico oficiales; el directorio a que se refiere este inciso
                    deberá
                    contener, ademas,
                    el nombre electrónico oficiales; el directorio a que se refiere este inicien redacción de correo de
                    todas las cuentas oficiales
                    de redes sociales digitales que administren los sujetos obligados, así como aquellas de los
                    servidores
                    públicos que voluntariamente
                    decidan incorporarse a dicho directorio en los términos establecidos por el Sistema Nacional de
                    Transparencia.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-k" (click)="fraccion1k()"><strong>k)</strong> El nombre
                    del
                    encargado y de los integrantes, teléfono, fax y correo electrónico del Comité de Transparencia.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-l" (click)="fraccion1l()"><strong>l)</strong> El nombre
                    del encargado, teléfono,
                    fax y correo electrónico de la Unidad.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-m" (click)="fraccion1m()"><strong>m)</strong> El manual y
                    formato de solicitud de información pública.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-n" (click)="fraccion1n()"><strong>n)</strong> Indice de
                    los expedientes
                    clasificados como reservados, por área responsable de la información y tema.</a>
                  <br><a href="admin/articulo-8/fraccion-1/in-n1" (click)="fraccion1n1()"><strong>ñ)</strong> La
                    estadística de las solicitudes de información pública
                    tendidas, precisando las procedentes, parcialmente procedentes e improcedentes; así como la
                    estadística de visitas a su sistema de consulta
                    electrónica.</a>
                </p>
                <p style="text-align: justify; font-size: 2vh;">
                  <br><strong>II. La información sobre el marco jurídico aplicable al y por el sujeto obligado, que
                    comprende:</strong>
                  <a href="admin/articulo-8/fraccion-2/in-a" (click)="fraccion2a()"><br><strong>a)</strong> Las
                    disposiciones de las
                    Constituciones Políticas Federal y Estatal.</a>
                  <a href="admin/articulo-8/fraccion-2/in-b" (click)="fraccion2b()"><br><strong>b)</strong> Los tratados
                    y convenciones internacionales suscritas por México.</a>
                  <a href="admin/articulo-8/fraccion-2/in-c" (click)="fraccion2c()"><br><strong>c)</strong> Las leyes
                    federales y estatales.</a>
                  <a href="admin/articulo-8/fraccion-2/in-d" (click)="fraccion2d()"><br><strong>d)</strong> Los
                    reglamentos federales, estatales y municipales.</a>
                  <a href="admin/articulo-8/fraccion-2/in-e" (click)="fraccion2e()"><br><strong>e)</strong> Los
                    decretos, acuerdos, criterios, políticas, reglas de operación y
                    demás normas jurídicas generales.</a>
                </p>
                <p style="text-align: justify; font-size: 2vh;">
                  <br><strong>III. La información sobre la planeación del desarrollo, aplicable al y por el sujeto
                    obligado, que comprende:</strong>
                  <a href="admin/articulo-8/fraccion-3/in-a" (click)="fraccion2a()"><br><strong>a)</strong> Los
                    apartados del Plan Nacional de Desarrollo que sirve de marco general a la
                    planeación de las areas relativas a las funciones del sujeto
                    obligado.</a>
                  <a href="admin/articulo-8/fraccion-3/in-b" (click)="fraccion3b()"><br><strong>b)</strong> Los
                    apartados de los programas federales.</a>
                  <a href="admin/articulo-8/fraccion-3/in-c" (click)="fraccion3c()"><br><strong>c)</strong> Los
                    apartados del Plan Estatal de Desarrollo.</a>
                  <a href="admin/articulo-8/fraccion-3/in-d" (click)="fraccion3d()"><br><strong>d)</strong> Los
                    programas estatales.</a>
                  <a href="admin/articulo-8/fraccion-3/in-e" (click)="fraccion3e()"><br><strong>e)</strong> Los
                    programas regionales.</a>
                  <a href="admin/articulo-8/fraccion-3/in-f" (click)="fraccion3f()"><br><strong>f)</strong> Las
                    evaluaciones y encuestas que hagan los sujetos obligados a programas
                    financiados con recursos publicos.</a>
                  <a href="admin/articulo-8/fraccion-3/in-g" (click)="fraccion3g()"><br><strong>g)</strong> Los demás
                    instrumentos de planeación no comprendidos en los incisos
                    anteriores.</a>
                </p>


                <p style="text-align: justify;font-size: 2vh;">
                  <br><strong>IV. La información sobre la planeación estratégica
                    gubernamental aplicable al y por el sujeto obligado, que comprende:</strong>
                  <a href="admin/articulo-8/fraccion-4/in-a" (click)="fraccion4a()"><br><strong>a)</strong> Plan General
                    Institucional del poder, organismo
                    o municipio correspondiente, con las modificaciones de cuando menos los ultimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-4/in-b" (click)="fraccion4b()"><br><strong>b)</strong> Los
                    programas operativos anuales, de cuando menos los últimos tres
                    años.</a>
                  <a href="admin/articulo-8/fraccion-4/in-c" (click)="fraccion4c()"><br><strong>c)</strong> Los manuales
                    de organización.</a>
                  <a href="admin/articulo-8/fraccion-4/in-d" (click)="fraccion4d()"><br><strong>d)</strong> Los manuales
                    de operación.</a>
                  <a href="admin/articulo-8/fraccion-4/in-e" (click)="fraccion4e()"><br><strong>e)</strong> Los manuales
                    de procedimientos.</a>
                  <a href="admin/articulo-8/fraccion-4/in-f" (click)="fraccion4f()"><br><strong>f)</strong> Los manuales
                    de servicios.</a>
                  <a href="admin/articulo-8/fraccion-4/in-g" (click)="fraccion4g()"><br><strong>g)</strong> Los
                    protocolos.</a>
                  <a href="admin/articulo-8/fraccion-4/in-h" (click)="fraccion4h()"><br><strong>h)</strong> Los
                    indicadores que permitan rendir cuenta de sus objetivos y resultados.</a>
                  <a href="admin/articulo-8/fraccion-4/in-i" (click)="fraccion4i()"><br><strong>i)</strong> Los demás
                    instrumentos normativos internos aplicables.</a>
                </p>
                <p style="text-align: justify;font-size: 2vh;">
                  <br><strong>V.La información financiera, patrimonial y administrativa, que comprende:</strong>
                  <a href="admin/articulo-8/fraccion-5/in-a" (click)="fraccion5a()"><br><strong>a)</strong> Las partidas
                    del Presupuesto de Egresos de la Federación y del Presupuesto de
                    Egresos del Estado, así como los
                    conceptos del clasificador por objeto del gasto, aplicables al y por el sujeto obligado, de cuando
                    menos los ultimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-b" (click)="fraccion5b()"><br><strong>b)</strong> Los ingresos
                    extraordinarios
                    recibidos por cualquier concepto, señalando el origen de los recursos, el nombre de los responsables
                    de recibirlos, administrarlos y ejercerlos, asi como el
                    proyectoo programa donde serán aplicados.</a>
                  <a href="admin/articulo-8/fraccion-5/in-c" (click)="fraccion5c()"><br><strong>c)</strong> El
                    presupuesto de egresos anual y, en su caso, el clasificador por objeto del
                    gasto del sujeto obligado, de cuando
                    menos los ultimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-d" (click)="fraccion5d()"><br><strong>d)</strong> Las
                    convocatorias a concursos para ocupar cargos públicos y los resultados de
                    los mismos.</a>
                  <a href="admin/articulo-8/fraccion-5/in-e" (click)="fraccion5e()"><br><strong>e)</strong> El
                    organigrama del sujeto obligado,
                    con las modificaciones de cuando menos los últimos tres años, asi como la plantilla del personal del
                    sujeto obligado, con las modificaciones de cuando menos
                    los uitimos tres años, en la que se incluya el numero total de plazas del personal de base, del
                    personal de confianza y las vacantes.</a>
                  <a href="admin/articulo-8/fraccion-5/in-f" (click)="fraccion5f()"><br><strong>f)</strong> Las
                    remuneraciones
                    mensuales por puesto, Incluidas todas las prestaciones, estimulos o compensaciones.</a>
                  <a href="admin/articulo-8/fraccion-5/in-g" (click)="fraccion5g()"><br><strong>g)</strong> Las nóminas
                    completas del sujeto obligado en las que se incluya las
                    gratificaciones, primas, comisiones, dietas y estimulos, de cuando menos los utimos tres años, y en
                    su
                    caso, con sistema de búsqueda.</a>
                  <a href="admin/articulo-8/fraccion-5/in-h" (click)="fraccion5h()"><br><strong>h)</strong> El listado
                    de jubilados
                    y pensionados y el monto que reciben.</a>
                  <a href="admin/articulo-8/fraccion-5/in-i" (click)="fraccion5i()"><br><strong>i)</strong> Los estados
                    financieros mensuales, de cuando menos los últimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-j" (click)="fraccion5j()"><br><strong>j)</strong> Los gastos
                    de comunicación social, de cuando
                    menos los ultimos tres años, donde se señale cuando menos la fecha, monto y partida de la erogación,
                    responsable directo de la autorización de la contratación,
                    denominación del medio de comunicación contratado, descripción del servicio contratado,
                    justificación
                    y relación con alguna función o servicio públicos.</a>
                  <a href="admin/articulo-8/fraccion-5/in-k" (click)="fraccion5k()"><br><strong>k)</strong> El contrato
                    de prestación de servicios o por honorarios, y el gasto realizado
                    por concepto de pago de asesorías al sujeto obligado,
                    donde se senale nombre de la empresa, institución o individuos, el concepto de cada una de las
                    asesorías, asi como el trabajo realizado.</a>
                  <a href="admin/articulo-8/fraccion-5/in-l" (click)="fraccion5l()"><br><strong>l)</strong>
                    Los subsidios, en especie o en numerario, recibidos por el sujeto obligado, así como los otorgados
                    por
                    el sujeto obligado, en los que se
                    señale lo siguiente:
                    <br> <strong>1.</strong> Area.
                    <br> <strong>2.</strong> Denominación del programa.
                    <br> <strong>3.</strong> Periodo de vigencia.
                    <br> <strong>4.</strong> Diseño, objetivos y alcances.
                    <br> <strong>5.</strong> Metas fisicas.
                    <br> <strong>6.</strong> Población beneficiada estimada.
                    <br> <strong>7.</strong> Monto aprobado, modificado y ejercido, así como los calendarios de su
                    programación presupuestal.
                    <br> <strong>8.</strong> Requisitos y procedimientos
                    de acceso.
                    <br> <strong>9.</strong> Procedimiento de queja o incontormidad ciudadana.
                    <br> <strong>10.</strong> Mecanismos de exigibilidad.
                    <br> <strong>11.</strong> Mecanismos de evaluación, informes de evaluación y seguimiento de
                    recomendaciones.
                    <br> <strong>12.</strong> Indicadores con nombre, definición, método de cálculo, unidad de medida,
                    dimensión, frecuencia de medición, nombre
                    de las bases de datos utilizadas para su cálculo.
                    <br> <strong>13.</strong> Formas de participación social.
                    <br> <strong>14.</strong> Articulación con otros programas sociales.
                    <br> <strong>15.</strong> Vinculo a las reglas
                    de operación o documento equivalente.
                    <br> <strong>16.</strong> Informes periódicos sobre la ejecución y los resultados de las
                    evaluaciones
                    realizadas.
                    <br> <strong>17.</strong> Padrón de beneficiarios
                    mismo que deberá contener nombre de la persona física o denominación social de las personas
                    jurídicas
                    beneficiarias, el monto, recurso, beneficio o apoyo otorgado
                    para cada una de ellas, unidad teritorial, edad y sexo.
                    <br><strong>18. </strong> Además de lo señalado en los numerales anteriores, en el caso de
                    donaciones, estimulos y apoyos
                    hechos a terceros en dinero o en especie, otorgados por el sujeto obligado, se deberá señalar el
                    concepto o nombre del donativo, estimulo o apoyo, monto, nombre
                    del benetficiario, temporalidad, criterios para otorgarlo, asi como el acta minuta u oficio de
                    aprobación.</a>
                  <a href="admin/articulo-8/fraccion-5/in-m" (click)="fraccion5m()"><br><strong>m)</strong> El listado
                    de personas fisicas o juridicas a quienes,
                    por cualquier motivo, se les asigne o permita usar recursos publicos o, en los términos de las
                    disposiciones aplicables,
                    realicen actos de autoridad, así como los informes que dichas personas les entreguen sobre el uso y
                    destino de dichos recursos.</a>
                  <a href="admin/articulo-8/fraccion-5/in-n" (click)="fraccion5n()"><br><strong>n)</strong> Las cuentas
                    públicas, las auditorías internas y externas, asi como los demás
                    informes de gestión financiera del sujeto obligado,
                    de cuando menos los uitimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-n1" (click)="fraccion5n1()"><br><strong>ñ)</strong> Los
                    padrones de proveedores o contratistas, de cuando menos los útimos tres
                    años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-o" (click)="fraccion5o()"><br><strong>o)</strong> La
                    información sobre adjudicaciones directas en materia de adquisiciones, obra
                    pública, proyectos de inversión y prestación de servicios,
                    de cuando menos los ultimos tres años, que deberá contener, por lo menos, lo siguiente:
                    <br><strong>1.</strong> El Programa Anual de Adquisiciones, Arrendamientos y Servicios.
                    <br><strong>2.</strong> La integración del Comité de Adquisiciones.
                    <br><strong>3.</strong> La solicitud de compra, adquisición o aprovisionamiento de las áreas
                    requirentes.
                    <br><strong>4.</strong> La propuesta enviada por el participante.
                    <br><strong>5.</strong> Los motivos y fundamentos legales aplicados para llevarla a cabo.
                    <br><strong>6.</strong> La autorización del ejercicio de la opción.
                    <br><strong>7.</strong> En su caso, las cotizaciones consideradas, especificando los nombres de los
                    proveedores y los montos.
                    <br><strong>8.</strong> El nombre de la persona física o jurídica adjudicada.
                    <br><strong>9.</strong> La unidad administrativa solicitante y la responsable de su ejecución.
                    <br><strong>10.</strong> El número, fecha, el monto del contrato y el plazo de entrega o de
                    ejecución
                    de los servicios u obra.
                    <br><strong>11.</strong> Los mecanismos de vigilancia y supervision, incluyendo, en su caso, los
                    estudios de impacto urbano y ambiental, segun corresponda.
                    <br><strong>12.</strong> La partida presupuestal, de conformidad con el clasificador por objeto del
                    gasto, en el caso de ser aplicable.
                    <br><strong>13.</strong> Origen de los recursos especificando si son federales, estatales o
                    municipales, así como el tipo de fondo de participación o aportación
                    respectiva.
                    <br><strong>14.</strong> Las actas de las sesiones que se realizan durante los procesos.
                    <br><strong>15.</strong> El video y audio de las sesiones que se
                    realizan durante los procesos.
                    <br><strong>16.</strong> Los informes de avance sobre las obras o servicios contratados.
                    <br><strong>17.</strong> El convenio de terminación.
                    <br><strong>18.</strong> El finiquito y evidencia de la entrega del bien o servicio.
                    <br><strong>19.</strong> La investigación de Mercado, una vez emitida la adjudicación.</a>
                  <a href="admin/articulo-8/fraccion-5/in-p" (click)="fraccion5p()"><br><strong>p)</strong> La
                    información sobre concursos por invitación y licitaciones públicas en
                    materia de adquisiciones, obra publica,
                    proyectos de inversión y prestación de servicios, de cuando menos los ultimos tres años, que debera
                    contener, por lo menos, lo siguiente:
                    <br><strong>1.</strong> La convocatoria o invitación emitida, asi como los fundamentos legales
                    aplicados para llevariaa a cabo.
                    <br><strong>2.</strong> Los nombres de los participantes o invitados.
                    <br><strong>3.</strong> El nombre del ganador y las razones que lo justifican.
                    <br><strong>4.</strong> El área solicitante y la responsable de su ejecución.
                    <br><strong>5.</strong> Las convocatoriase invitaciones emitidas.
                    <br><strong>6.</strong> Los dictámenes y fallo de adjudicación.
                    <br><strong>7.</strong> El contrato y, en su caso, sus anexos.
                    <br><strong>8.</strong> Los mecanismos de vigilancia y supervisión, incluyendo, en su caso, los
                    estudios de impacto urbano y ambiental, segun corresponda.
                    <br><strong>9. </strong> La partida presupuestal, de conformidad con el clasificador por objeto del
                    gasto, en el caso de ser aplicable.
                    <br><strong>10.</strong> Origen de los recursos especificando si son federales, estatales o
                    municipales, así como el tipo de fondo de participación o aportación respectiva.
                    <br><strong>11.</strong> Los convenios modificatorios que, en su caso, sean firmados, precisando el
                    objeto y la fecha de celebración.
                    <br><strong>12.</strong> Los informes de avance físico y financiero sobre las obras o servicios
                    contratados.
                    <br><strong>13.</strong> El convenio de terminación.
                    <br><strong>14.</strong> El finiquito.</a>
                  <a href="admin/articulo-8/fraccion-5/in-q" (click)="fraccion5q()"><br><strong>q)</strong> El nombre,
                    denominación o razón social y clave del Registro Federal de
                    Contribuyentes de quienes se les hubiera cancelado o condonado algún
                    crédito fiscal, los montos respectivos, asi como la información estadistica sobre las exenciones
                    previstas en las disposiciones fiscales.</a>
                  <a href="admin/articulo-8/fraccion-5/in-r" (click)="fraccion5r()"><br><strong>r) </strong> Los
                    inventarios de bienes muebles e inmuebles del sujeto obligado, de cuando
                    menos los últimos tres años, donde se señale
                    cuando menos la descripcion, el valor, el regimen Juridico, y el uso o afectación del bien.</a>
                  <a href="admin/articulo-8/fraccion-5/in-s" (click)="fraccion5s()"><br><strong> s)</strong> Los gastos
                    de representación, viáticos y viajes oficiales, su costo,
                    itinerario, agenda y resultados.</a>
                  <a href="admin/articulo-8/fraccion-5/in-t" (click)="fraccion5t()"><br><strong>t)</strong> Las
                    concesiones, licencias, permisos o autorizaciones otorgadas de los útimos
                    tres años.</a>
                  <a href="admin/articulo-8/fraccion-5/in-u" (click)="fraccion5u()"><br><strong>u)</strong> Los decretos
                    y expedientes relativos a las expropiaciones que realicen por
                    utilidad pública.</a>
                  <a href="admin/articulo-8/fraccion-5/in-v" (click)="fraccion5v()"><br><strong>v)</strong> Las pólizas
                    de los cheques expedidos, con identificación del número de cheque
                    o transferencia, monto y nombre del beneficiario,
                    indicando el motivo de la erogación, en el que de manera detallada y completa se indique para que se
                    erogo el recurso publico,
                    o en su caso la descripción que aparezca en la factura correspondiente; siempre y cuando con ello se
                    aporten los elementos cualitativos y cuantitativos de la finalidad del cheque o transferencia.</a>
                  <a href="admin/articulo-8/fraccion-5/in-w" (click)="fraccion5w()"><br><strong>w)</strong> El estado de
                    la deuda pública del sujeto obligado, donde se señale Cuando
                    menos responsable de la autorización,
                    fecha de contratación, monto del crédito, tasa de interés, monto total
                    amortizable, plazo de vencimiento, institución crediticia, objeto de aplicación y avance de
                    aplicación
                    de cada deuda contratada.</a>
                  <a href="admin/articulo-8/fraccion-5/in-x" (click)="fraccion5x()"><br><strong>x)</strong> Los estados
                    de cuenta bancarios que expiden las instituciones financieras,
                    número de cuentas bancarias, estados financieros,
                    cuentas de fideicomisos e inversiones, de cuando menos los últimos seis meses.</a>
                  <a href="admin/articulo-8/fraccion-5/in-y" (click)="fraccion5y()"><br><strong>y)</strong> La
                    información en versión pública de las declaraciones patrimoniales de los
                    servidores públicos que así lo determinen,
                    en los sistemas habilitados para ello, de acuerdo a la normatividad aplicable.</a>
                  <a href="admin/articulo-8/fraccion-5/in-z" (click)="fraccion5z()"><br><strong>z)</strong> El registro
                    de los procedimientos de responsabilidad administrativa, con
                    indicación del número de expediente,
                    fecha de ingreso, nombre del denunciante, nombre y cargo del denunciado, causa del procedimiento,
                    estado procesal y, en su caso, la sanción impuesta.</a>
                </p>
                <p style="text-align: justify;font-size: 2vh;">
                  <br><strong>VI. La información sobre la gestión pública, que comprende:</strong>
                  <a href="admin/articulo-8/fraccion-6/in-a" (click)="fraccion6a()"><br><strong>a)</strong> Las
                    funciones públicas que realiza el sujeto obligado, donde se señale cuando
                    menos el fundamento legal, la descripción
                    de la función pública, así como los recursos materiales, humanos y financieros asignados para la
                    realización de la función pública.</a>
                  <a href="admin/articulo-8/fraccion-6/in-b" (click)="fraccion6b()"><br><strong>b)</strong> Los
                    servicios públicos que presta el sujeto obligado, donde se señale cuando
                    menos la descripción y cobertura del servicio público;
                    los recursos materiales, humanos y financieros asignados para la prestación del servicio público, y
                    el
                    número y tipo de beneficiarios
                    directos e indirectos del servicio público.</a>
                  <a href="admin/articulo-8/fraccion-6/in-c" (click)="fraccion6c()"><br><strong>c)</strong> Las obras
                    públicas que realiza el sujeto obligado, de cuando menos los últimos
                    tres años, donde se señale cuando menos la descripción y
                    ubicación de la obra; el ejecutor y supervisor de la obra; el costo inicial y final; la superficie
                    construida por metros cuadrados
                    costo por metro cuadrado; Su relación con los instrumentos de planeación del desarrollo, y el número
                    y
                    tipo de beneficiarios directos e indirectos de la obra.</a>
                  <a href="admin/articulo-8/fraccion-6/in-d" (click)="fraccion6d()"><br><strong>d)</strong> Los
                    programas sociales que aplica el sujeto obligado, de cuando menos los
                    últimos tres años, donde se señale cuando menos los objetivos, metas,
                    presupuesto y reglas de operacion del programa; los requisitos, trámites y formatos para ser
                    beneficiario; la entidad pública ejecutora, el responsable
                    directo, número de personal que lo aplica y el costo de operación del programa el padrón de
                    beneficiarios del programa, y la medición de avances de la
                    ejecución del gasto, y el cumplimiento de metas y objetivos del programa, incluida la metodología
                    empleada.</a>
                  <a href="admin/articulo-8/fraccion-6/in-e" (click)="fraccion6e()"><br><strong>e)</strong> Las
                    políticas públicas que elabora y aplica el sujeto obligado, de cuando
                    menos los últimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-6/in-f" (click)="fraccion6f()"><br><strong>f)</strong> Los
                    convenios, contratos y demas instrumentos juridicos suscritos por el
                    sujeto obligado, de Cuando menos los ultimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-6/in-g" (click)="fraccion6g()"><br><strong>g)</strong> Las
                    concesiones, licencias, permisos, autorizaciones y demás actos
                    administrativos otorgados por el sujeto obligado, de cuando menos los ultimos
                    tres años, en el que se incluyan los requisitos para acceder a ellos y, en su caso, los formatos
                    correspondientes.</a>
                  <a href="admin/articulo-8/fraccion-6/in-h" (click)="fraccion6h()"><br><strong>h)</strong> La agenda
                    diaria de actividades del sujeto obligado, de cuando menos el último
                    mes.</a>
                  <a href="admin/articulo-8/fraccion-6/in-i" (click)="fraccion6i()"><br><strong>i)</strong> El lugar,
                    día y hora de las todas las reuniones o sesiones de sus órganos
                    colegiados, junto con el orden del día y una relación detallada de los
                    asuntos a tratar, asi como la indicacion del lugar y torma en que se puedan consultar los documentos
                    publicos relativos, con cuando menos veinticuatro
                    horas anteriores a la celebración de dicha reunión o sesión.</a>
                  <a href="admin/articulo-8/fraccion-6/in-j" (click)="fraccion6j()"><br><strong>j)</strong> Las
                    versiones estenográficas, asi como las actas o minutas de las reuniones o
                    sesiones de sus órganos colegiados.</a>
                  <a href="admin/articulo-8/fraccion-6/in-k" (click)="fraccion6k()"><br><strong>k)</strong> La
                    integración, la regulación básica y las actas de las reuniones de los
                    consejos ciudadanos reconocidos oficialmente por el sujeto obligado con
                    el propósito de que la ciudadanía participe o vigile la actividad de sus órganos y dependencias.</a>
                  <a href="admin/articulo-8/fraccion-6/in-l" (click)="fraccion6l()"><br><strong>l)</strong> Los informes
                    trimestrales y anuales de actividades del sujeto obligado, de
                    cuando menos los últimos tres años.</a>
                  <a href="admin/articulo-8/fraccion-6/in-m" (click)="fraccion6m()"><br><strong>m)</strong> Las
                    recomendaciones emitidas por los órganos públicos del Estado mexicano u
                    organismos internacionales garantes de los derechos humanos,
                    así como las acciones que han llevado a cabo para su atención.</a>
                  <a href="admin/articulo-8/fraccion-6/in-n" (click)="fraccion6n()"><br><strong>n)</strong> Las
                    estadísticas que generen en cumplimiento de sus facultades, competencias o
                    funciones
                    con la mayor desagregación posible.</a>
                </p>
                <a href="admin/articulo-8/fraccion-7" (click)="a8fraccion7()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>VII. Las versiones públicas de las resoluciones y laudos que emitan los sujetos
                      obligados, en procesos o procedimientos seguidos en forma de
                      juicio y que hayan causado estado.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-8" (click)="a8fraccion8()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>VIII. Los mecanismos e instrumentos de participación ciudadana que puedan
                      accedero ejercer ante el sujeto obligado.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-9" (click)="a8fraccion9()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>IX. La información pública ordinaria, proactiva o focalizada que considere el sujeto
                      obligado, por sí
                      o a propuesta del Instituto.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-10" (click)="a8fraccion10()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>X. Las condiciones generales de trabajo, contratos o convenios que regulen las
                      relaciones laborales del personal de base o de confianza,
                      así como los recursos públicos económicos, en especie o donativos, que sean entregados a los
                      sindicatos y ejerzan como recursos públicos.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-11" (click)="a8fraccion11()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>XI. Los estudios financiados con recursos públicos.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-12" (click)="a8fraccion12()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>XII.Los ingresos recibidos
                      por cualquier concepto señalando el nombre de los responsables
                      de recibirlos, administrarlos y ejercerlos, así como su destino, indicando el destino de cada uno
                      de
                      ellos.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-13" (click)="a8fraccion13()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>XIII. El catálogo de disposición y guía de archivo baja documental y transferencia
                      secundaria, programa e informe anual de desarrollo archivístico y
                      actas de documentación siniestrada; así como, los resultados de las auditorías archivísticas, las
                      determinaciones y resoluciones
                      del Consejo Estatal de Archivos.</strong>
                  </p>
                </a>
                <a href="admin/articulo-8/fraccion-14" (click)="a8fraccion14()">
                  <p style="text-align: justify;font-size: 2vh;">
                    <br><strong>XIV. La demás información pública a que obliguen las disposiciones federales y la
                      Ley General de Contabilidad Gubernamental, así como aquella que
                      se genere por la ejecución del gasto público con recursos federales.</strong>
                  </p>
                </a>

                <p style="text-align: justify;font-size: 2vh;">
                  <br><strong>2.La publicación de información fundamental debe realizarse con independencia de
                    su publicación oficial y debe reunir los requisitos de
                    claridad, calidad, certeza, veracidad, oportunidad y confiabilidad.</strong>
                </p>
















              </div>



            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
