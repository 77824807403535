import { Component } from '@angular/core';

@Component({
  selector: 'app-tramites-y-servicios',
  templateUrl: './tramites-y-servicios.component.html',
  styleUrls: ['./tramites-y-servicios.component.scss']
})
export class TramitesYServiciosComponent {

}
