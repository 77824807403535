import { Component } from '@angular/core';

@Component({
  selector: 'app-gobierno',
  templateUrl: './gobierno.component.html',
  styleUrls: ['./gobierno.component.scss']
})
export class GobiernoComponent {

}
