import { Component } from '@angular/core';

@Component({
  selector: 'app-documento-im',
  templateUrl: './documento-im.component.html',
  styleUrls: ['./documento-im.component.scss']
})
export class DocumentoIMComponent {

}
