<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">

    <section style="margin-top: 40px;">
      <app-menu-compartido></app-menu-compartido>
      <div class="section-title" data-aos="fade-up">
        <h4 style="text-align: left; margin-left: 160px;margin-top:3vh">El organigrama del sujeto obligado, con las modificaciones de
          cuando menos los últimos tres años,
          asi como la plantilla del personal del sujeto obligado,
          con las modificaciones de cuando menos los uitimos tres años,
          en la que se incluya el numero total de plazas del personal de base, del personal de confianza y las vacantes
        </h4>
      </div>
    </section>

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Inicio</li>
            <li><a href="admin/articulo-8">Articulo 8</a></li>
            <li>Fracción V</li>
            <li>Inciso E</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->



    <section id="blog" class="blog">
      <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
        <button style="height: 38px;" (click)="seleccionarTodo()" type="button"
        class="btn btn-outline-dark">Seleccionar Todo</button>
        <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion()" type="button"
        class="btn btn-outline-dark">Desahacer Selección</button>
        <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
        class="btn btn-outline-dark">Eliminar Seleccionados</button>
      </div>
      <div class="fondo" data-aos="fade-up">

        <div id="scroll">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del documento</th>
                <th scope="col"></th>

              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let dato of docs, let i = index">
                <td><input type="checkbox" (change)="checkCheckBoxvalue($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo">
                  <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                  <strong> {{dato?.ANOTACIONES}}</strong>
                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                </td>
                <td>
                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                    class="btn btn-outline-dark">Eliminar</button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>



    </section>
  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
