<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 80px;">
    <div class="section-title" data-aos="fade-up">
      <h4>Cargar documento (Artículo 8):</h4>
      <div class="fondo" data-aos="fade-up">
        <div style="justify-content:center; text-align:center;">
          <form [formGroup]="documentoForm">
            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; ">Seleccione la fracción:</label>
            <select formControlName="FRACCION">
              <option SE value="1">I</option>
              <option value="2">II</option>
              <option value="3">III</option>
              <option value="4">IV</option>
              <option value="5">V</option>
              <option value="6">VI</option>
              <option value="7">VII</option>
              <option value="8">VIII</option>
              <option value="9">IX</option>
              <option value="10">X</option>
              <option value="11">XI</option>
              <option value="12">XII</option>
              <option value="13">XIII</option>
              <option value="14">XIV</option>
            </select>
            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Seleccione el inciso:</label>
            <select formControlName="INCISO">
              <option value="0">No Aplica</option>
              <option value="1">a</option>
              <option value="2">b</option>
              <option value="3">c</option>
              <option value="4">d</option>
              <option value="5">e</option>
              <option value="6">f</option>
              <option value="7">g</option>
              <option value="8">h</option>
              <option value="9">i</option>
              <option value="10">j</option>
              <option value="11">k</option>
              <option value="12">l</option>
              <option value="13">m</option>
              <option value="14">n</option>
              <option value="15">ñ</option>
              <option value="16">o</option>
              <option value="17">p</option>
              <option value="18">q</option>
              <option value="19">r</option>
              <option value="20">s</option>
              <option value="21">t</option>
              <option value="22">u</option>
              <option value="23">v</option>
              <option value="24">w</option>
              <option value="25">x</option>
              <option value="26">y</option>
              <option value="27">z</option>





            </select>
            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold;">Seleccione el AREA:</label>
            <select formControlName="AREA">

              <option selected value="0">Sin Area</option>
              <option value="1">Coordinacion administrativa y contable</option>
              <option value="2">Coordinación de promoción, difusion y juridico</option>
              <option value="3">Direccion</option>
              <option value="4">Unidad de Transparencia</option>
            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione Carpeta:</label>
            <select formControlName="CARPETA">

              <option selected value="0">Sin Carpeta</option>

              <optgroup label="Fracción 6 - Inciso I y J">
                <option value="1">Junta de Gobierno</option>
                <option value="2">Prevención del Embarazo en NNA</option>
                <option value="3">Sistemas</option>
              </optgroup>


              <optgroup label="Fracción 8">
                <option value="4">Galardón Cihuatl a la Mujer Autlense</option>
                <option value="5">Galardón Cihuatl al Hombre Autlense</option>
                <option value="6">Proyecto de Fortalecimiento a la Transversalidad de la Perspectiva de Género</option>
              </optgroup>

            </select>

            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el año:</label>
            <select formControlName="ANO">
              <option value="0">No Aplica</option>
              <option value="2016">2016</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2000">Trimestres 2018</option>
              <option value="2001">Trimestres 2019</option>
              <option value="2002">Trimestres 2020</option>
              <option value="2003">Trimestres 2021</option>
              <option value="2004">Trimestres 2022</option>
              <option value="3004">Trimestres 2023</option>
              <option value="3005">Trimestres 2024</option>
              <option value="3006">Trimestres 2025</option>
            </select>


            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el Mes:</label>
            <select formControlName="MES">
              <option value="0">No Aplica</option>
              <option value="1">Enero</option>
              <option value="2">Febrero</option>
              <option value="3">Marzo</option>
              <option value="4">Abril</option>
              <option value="5">Mayo</option>
              <option value="6">Junio</option>
              <option value="7">Julio</option>
              <option value="8">Agosto</option>
              <option value="9">septiembre</option>
              <option value="10">octubre</option>
              <option value="11">Noviembre</option>
              <option value="12">Diciembre</option>

            </select>






            <br><label style="margin-top: 2%; margin-right: 1%; font-weight: bold; font-weight: bold;">Seleccione el documento a subir</label>
            <br><input type="file" style="margin-top: 1%;" (change)="contarArchivos($event)" formControlName="NOMBRE"
              multiple>

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>

          </form>
        </div>
      </div>
    </div>

  </section>


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
