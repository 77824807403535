import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RespuestaAPI } from "src/interface/api-responses.model";
import { environment } from 'src/environments/environment';
import { Observable, Subject } from "rxjs";


const API = "https://api.guarderia.saion.mx";



var token = localStorage.getItem('token');

if (token === null) {
  token = '';
}

const headers = new HttpHeaders({
  'Authorization': 'App' + token!
});

@Injectable({
  providedIn: 'root'
})

export class GeneralService{

  constructor(private http:HttpClient){}

  //---Observable para indicar URL de foto obtenida---
  urlFoto: any;
  private subject = new Subject<any>();
  url: string = API + "/";

  nuevoDoc(form: any) {
    let direccion = this.url + "autlan/imma/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  nuevoeEnlace(form: any) {
    let direccion = this.url + "autlan/nuevo/enlace";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  verEnlaces() {
    return this.http.get<RespuestaAPI>(API +`/autlan/enlaces`);
  }

  editarDoc(form:any){
    let direccion = this.url + "autlan/actualizar"
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
}

  obtener(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/${id}`);
  }
  obtenerA8(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/${id}/${inciso}`);
  }

  obtenerA152018(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2018/${id}`);
  }

  obtenerA152019(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2019/${id}`);
  }

  obtenerA152020(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2020/${id}`);
  }

  obtenerA152021(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2021/${id}`);
  }

  obtenerA152022(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2022/${id}`);
  }




  obtenerImmaA(id: any, inciso: any,ano:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/ano/${id}/${inciso}/${ano}`);
  }

  obtenerA82018(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/A8/2018/${id}/${inciso}`);
  }

  obtenerA82019(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/A8/2019/${id}/${inciso}`);
  }

  obtenerA82020(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/A8/2020/${id}/${inciso}`);
  }

  obtenerA82021(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/A8/2021/${id}/${inciso}`);
  }

  obtenerA82022(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/imma/A8/2022/${id}/${inciso}`);
  }
  obtenerSUBSIDIO(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/${id}/${inciso}`);
  }
  obtenerIdentificadorDocumentos(idEmpresa: any){
    let direccion = API + `/preregistro/obtenerIdentificadorDocumentos/${idEmpresa}`
    return this.http.get<RespuestaAPI>(direccion);
  }
  obtenerIdentificadorDocumentosAutlan(idEmpresa: any){
    let direccion = API + `/preregistro/obtenerIdentificadorDocumentos/${idEmpresa}`
    return this.http.get<RespuestaAPI>(direccion);
  }
  eliminarEnlace(form: any){
    const direccion = API + "/autlan/actualizar/imma";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  limpiarEnlaces(){
    const direccion = API + "/autlan/eliminar";
    return this.http.get<RespuestaAPI>(direccion);
  }

  actualizarNoticia(form: any){
    const direccion = API + "/autlan/IMMA/noticia/actualizar";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  verNoticias(){
    const direccion = API + "/autlan/IMMA/noticias";
    return this.http.get<RespuestaAPI>(direccion);
  }
  verBanner(){
    const direccion = API + "/autlan/banner/IMMA";
    return this.http.get<RespuestaAPI>(direccion);
  }
  actualizarBanner(form: any){
    const direccion = API + "/autlan/banner/IMMA/actualizar";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }
}
