import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.scss']
})
export class BlogSingleComponent implements OnInit {

  boton : any;

  constructor(private router : Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }

  subirDocumento(){
    localStorage.setItem('articulo', '8');
    console.log(localStorage.getItem('articulo'));
    this.router.navigate(['admin/documento']);
  }

  fraccion1a(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','1')
  }
  fraccion1b(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','2')
  }
  fraccion1c(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','3')
  }
  fraccion1d(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','4')
  }
  fraccion1e(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','5')
  }
  fraccion1f(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','6')
  }
  fraccion1g(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','7')
  }
  fraccion1h(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','8')
  }
  fraccion1i(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','9')
  }
  fraccion1j(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','10')
  }
  fraccion1k(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','11')
  }
  fraccion1l(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','12')
  }
  fraccion1m(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','13')
  }
  fraccion1n(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','14')
  }
  fraccion1n1(){
    localStorage.setItem('fraccion','1')
    localStorage.setItem('inciso','15')
  }
  fraccion2a(){
    localStorage.setItem('fraccion','2')
    localStorage.setItem('inciso','1')
  }
  fraccion2b(){
    localStorage.setItem('fraccion','2')
    localStorage.setItem('inciso','2')
  }
  fraccion2c(){
    localStorage.setItem('fraccion','2')
    localStorage.setItem('inciso','3')
  }
  fraccion2d(){
    localStorage.setItem('fraccion','2')
    localStorage.setItem('inciso','4')
  }
  fraccion2e(){
    localStorage.setItem('fraccion','2')
    localStorage.setItem('inciso','5')
  }
  fraccion3a(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','1')
  }
  fraccion3b(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','2')
  }
  fraccion3c(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','3')
  }
  fraccion3d(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','4')
  }
  fraccion3e(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','5')
  }
  fraccion3f(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','6')
  }
  fraccion3g(){
    localStorage.setItem('fraccion','3')
    localStorage.setItem('inciso','7')
  }
  fraccion4a(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','1')
  }
  fraccion4b(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','2')
  }
  fraccion4c(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','3')
  }
  fraccion4d(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','4')
  }
  fraccion4e(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','5')
  }
  fraccion4f(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','6')
  }
  fraccion4g(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','7')
  }
  fraccion4h(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','8')
  }
  fraccion4i(){
    localStorage.setItem('fraccion','4')
    localStorage.setItem('inciso','9')
  }
  fraccion5a(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','1')
  }
  fraccion5b(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','2')
  }
  fraccion5c(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','3')
  }
  fraccion5d(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','4')
  }
  fraccion5e(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','5')
  }
  fraccion5f(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','6')
  }
  fraccion5g(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','7')
  }
  fraccion5h(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','8')
  }
  fraccion5i(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','9')
  }
  fraccion5j(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','10')
  }
  fraccion5k(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','11')
  }
  fraccion5l(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','12')
  }
  fraccion5m(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','13')
  }
  fraccion5n(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','14')
  }
  fraccion5n1(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','15')
  }
  fraccion5o(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','16')
  }
  fraccion5p(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','17')
  }
  fraccion5q(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','18')
  }
  fraccion5r(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','19')
  }
  fraccion5s(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','20')
  }
  fraccion5t(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','21')
  }
  fraccion5u(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','22')
  }
  fraccion5v(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','23')
  }
  fraccion5w(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','24')
  }
  fraccion5x(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','25')
  }
  fraccion5y(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','26')
  }
  fraccion5z(){
    localStorage.setItem('fraccion','5')
    localStorage.setItem('inciso','27')
  }
  fraccion6a(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','1')
  }
  fraccion6b(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','2')
  }
  fraccion6c(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','3')
  }
  fraccion6d(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','4')
  }
  fraccion6e(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','5')
  }
  fraccion6f(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','6')
  }
  fraccion6g(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','7')
  }
  fraccion6h(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','8')
  }
  fraccion6i(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','9')
  }
  fraccion6j(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','10')
  }
  fraccion6k(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','11')
  }
  fraccion6l(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','12')
  }
  fraccion6m(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','13')
  }
  fraccion6n(){
    localStorage.setItem('fraccion','6')
    localStorage.setItem('inciso','14')
  }
  a8fraccion7(){
    localStorage.setItem('fraccion','7')
  }
  a8fraccion8(){
    localStorage.setItem('fraccion','8')
  }
  a8fraccion9(){
    localStorage.setItem('fraccion','9')
  }
  a8fraccion10(){
    localStorage.setItem('fraccion','10')
  }
  a8fraccion11(){
    localStorage.setItem('fraccion','11')
  }
  a8fraccion12(){
    localStorage.setItem('fraccion','12')
  }
  a8fraccion13(){
    localStorage.setItem('fraccion','13')
  }
  a8fraccion14(){
    localStorage.setItem('fraccion','14')
  }

  fraccion1(){
    localStorage.setItem('fraccion','1')
  }

  fraccion2(){
    localStorage.setItem('fraccion','2')
  }
  fraccion3(){
    localStorage.setItem('fraccion','3')
  }
  fraccion4(){
    localStorage.setItem('fraccion','4')
  }
  fraccion5(){
    localStorage.setItem('fraccion','5')
  }
  fraccion6(){
    localStorage.setItem('fraccion','6')
  }
  fraccion7(){
    localStorage.setItem('fraccion','7')
  }
  fraccion8(){
    localStorage.setItem('fraccion','8')
  }
  fraccion9(){
    localStorage.setItem('fraccion','9')
  }
  fraccion10(){
    localStorage.setItem('fraccion','10')
  }
  fraccion11(){
    localStorage.setItem('fraccion','11')
  }
  fraccion12(){
    localStorage.setItem('fraccion','12')
  }
  fraccion13(){
    localStorage.setItem('fraccion','13')
  }
  fraccion14(){
    localStorage.setItem('fraccion','14')
  }
  fraccion15(){
    localStorage.setItem('fraccion','15')
  }
  fraccion16(){
    localStorage.setItem('fraccion','16')
  }
  fraccion17(){
    localStorage.setItem('fraccion','17')
  }
  fraccion18(){
    localStorage.setItem('fraccion','18')
  }
  fraccion19(){
    localStorage.setItem('fraccion','19')
  }
  fraccion20(){
    localStorage.setItem('fraccion','20')
  }
  fraccion21(){
    localStorage.setItem('fraccion','21')
  }
  fraccion22(){
    localStorage.setItem('fraccion','22')
  }
  fraccion23(){
    localStorage.setItem('fraccion','23')
  }
  fraccion24(){
    localStorage.setItem('fraccion','24')
  }
  fraccion25(){
    localStorage.setItem('fraccion','25')
  }
  fraccion26(){
    localStorage.setItem('fraccion','26')
  }

  fraccion27(){
    localStorage.setItem('fraccion','27')
  }
  fraccion28(){
    localStorage.setItem('fraccion','28')
  }
}
