import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-subir-noticia',
  templateUrl: './subir-noticia.component.html',
  styleUrls: ['./subir-noticia.component.scss']
})
export class SubirNoticiaComponent {

  documentoForm = new FormGroup({
    ID: new FormControl(''),
    ENCABEZADO: new FormControl(''),
    CUERPO: new FormControl(''),
    FECHA: new FormControl(''),
    ENLACE:new FormControl(''),
  })

    //Variables para url de archivos
    urlDocumento = '';

    //Variables para indicar carga de archivos
    subiendoDocumento = false;

    fdia:any;
    fmes:any;
    fanio:any;
    docs:any;
    fecha:any;
    idNoticia : any;

  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage,private router: Router) { }

  ngOnInit(): void {
    this.idNoticia = localStorage.getItem('idNoticia');

  }

  public Editor = ClassicEditor;

  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
        })
      })


      async changeFileMenu(event: any) {

        let file = event.target.files[0];

        //TODO: Obtenemos nombre identificador para el documento
        this.getIdentificadorDocumentos.then(res => {
          let identificador = res;
          this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
          //TODO: Subimos archivo a Firebase


          const name ='- Autlan';

          let path = 'Noticias' ;  //Construimos ruta
          let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

          //Subimos el archivo a Firebase
          uploadBytes(fileRef, file).then(response => {
            //TODO: Descargamos la URL del archivo
            getDownloadURL(fileRef).then(file => {
              this.urlDocumento = file; //guardamos la url del archivo en una variable
              this.subiendoDocumento = false;
              console.log(file);

              this.documentoForm.value.ENLACE = file;
              console.log(this.documentoForm.value.ENLACE);

            }).catch(error => { console.log(error) });

          })
        })

      }

      dia(event:any){
        this.fdia =  event.target.value
        console.log(this.fdia);
      }

      mes(event:any){
        this.fmes =  event.target.value
        console.log(this.fmes);
      }

      anio(event:any){
        this.fanio =  event.target.value
        console.log(this.fanio);
      }

  cargar(){
    this.documentoForm.value.ID = this.idNoticia;
    this.documentoForm.value.FECHA = 'IMMA, ' + this.fmes + ' ' + this.fdia + ', ' + this.fanio;
    console.log(this.documentoForm.value.ID);
    console.log(this.documentoForm.value);
    if(this.documentoForm.value.ENCABEZADO !== '' &&
       this.documentoForm.value.ENLACE !== ''&&
       this.documentoForm.value.CUERPO !== ''){
        console.log(this.documentoForm.value);
        this.api.actualizarNoticia(this.documentoForm.value).subscribe(res=>{
          console.log('noticia subida');
          this.alerts.alertaRealizadoAsistencia('Completado','La noticia se ha cargado con exito');
          this.router.navigate(['admin/ver-noticia/' + this.idNoticia]);
        })
    }else{
      this.alerts.alertaError('Error','Llene los campos necesarios');
    }
  }

}
