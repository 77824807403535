<section id="blog" class="blog">
  <div style="margin-top: 150px;" class="container" data-aos="fade-up">

    <div class="row">

      <div class="col-lg-12 entries">

        <article class="entry entry-single">
          <div class="row">
              <div class="col-10">

              </div>

              </div>

          <div class="entry-content">


<div class="row">
<div class="col-12">
<img width="600" height="800" style="text-align: center; justify-content: center; margin-left: 20%;"  src="https://i.imgur.com/b7eIQid.png" >
</div>
</div>

<div class="row">
  <div class="col-12">
  <img width="600" height="800" style="text-align: center; justify-content: center; margin-left: 20%;"  src="https://i.imgur.com/BZnv4Ok.pngg" >
  </div>
  </div><div class="row">
    <div class="col-12">
    <img width="600" height="800" style="text-align: center; justify-content: center; margin-left: 20%;"  src="https://i.imgur.com/XlqbrOv.png" >
    </div>
    </div><div class="row">
      <div class="col-12">
      <img width="600" height="800" style="text-align: center; justify-content: center; margin-left: 20%;"  src="https://i.imgur.com/tPnoLCm.png" >
      </div>
      </div><div class="row">
        <div class="col-12">
        <img width="600" height="400" style="text-align: center; justify-content: center; margin-left: 20%;"  src="https://i.imgur.com/Y0UmHll.png" >
        </div>
        </div>


          </div>



        </article><!-- End blog entry -->


      </div><!-- End blog entries list -->

      <div class="col-lg-4">

        <!-- <div class="sidebar">



          <h3 class="sidebar-title">Recent Posts</h3>
          <div class="sidebar-item recent-posts">
            <div class="post-item clearfix">
              <img src="/assets/img/blog/blog-recent-1.jpg" alt="">
              <h4><a href="blog-single.html">Nihil blanditiis at in nihil autem</a></h4>
              <time datetime="2020-01-01">Jan 1, 2020</time>
            </div>

            <div class="post-item clearfix">
              <img src="/assets/img/blog/blog-recent-2.jpg" alt="">
              <h4><a href="blog-single.html">Quidem autem et impedit</a></h4>
              <time datetime="2020-01-01">Jan 1, 2020</time>
            </div>

            <div class="post-item clearfix">
              <img src="/assets/img/blog/blog-recent-3.jpg" alt="">
              <h4><a href="blog-single.html">Id quia et et ut maxime similique occaecati ut</a></h4>
              <time datetime="2020-01-01">Jan 1, 2020</time>
            </div>

            <div class="post-item clearfix">
              <img src="/assets/img/blog/blog-recent-4.jpg" alt="">
              <h4><a href="blog-single.html">Laborum corporis quo dara net para</a></h4>
              <time datetime="2020-01-01">Jan 1, 2020</time>
            </div>

            <div class="post-item clearfix">
              <img src="/assets/img/blog/blog-recent-5.jpg" alt="">
              <h4><a href="blog-single.html">Et dolores corrupti quae illo quod dolor</a></h4>
              <time datetime="2020-01-01">Jan 1, 2020</time>
            </div>

          </div> End sidebar recent posts

        <h3 class="sidebar-title">Tags</h3>
          <div class="sidebar-item tags">
            <ul>
              <li><a href="#">App</a></li>
              <li><a href="#">IT</a></li>
              <li><a href="#">Business</a></li>
              <li><a href="#">Mac</a></li>
              <li><a href="#">Design</a></li>
              <li><a href="#">Office</a></li>
              <li><a href="#">Creative</a></li>
              <li><a href="#">Studio</a></li>
              <li><a href="#">Smart</a></li>
              <li><a href="#">Tips</a></li>
              <li><a href="#">Marketing</a></li>
            </ul>
          </div>End sidebar tags

        </div>
         -->


        <!-- End sidebar -->

      </div><!-- End blog sidebar -->

    </div>

  </div>
</section><!-- End Blog Single Section -->
