import { Component } from '@angular/core';

@Component({
  selector: 'app-dif',
  templateUrl: './dif.component.html',
  styleUrls: ['./dif.component.scss']
})
export class DIFComponent {

}
